.gradientBacking {
  @apply absolute top-0 left-0 h-full w-full z-10;
  background: linear-gradient(to bottom right, var(--navy-dark), #4e260c);
  background-size: 100% 100%;
}

.overlayContainer {
  @apply relative h-full w-full transform skew-y-6 z-20;
}

.overlayInnerContainer {
  @apply relative h-full w-full;
  animation: yAxis 4s alternate infinite ease-in-out;
}

.overlayCircle {
  @apply rounded-full absolute opacity-90;
  filter: blur(5rem);
}

.primaryOverlay {
  composes: overlayCircle;
  z-index: 2;
  bottom: -2rem;
  left: -2rem;
  height: 14rem;
  width: 14rem;
  background: radial-gradient(#4bc2fe, #1553af);
  box-shadow: 0 0 6rem 6rem #1553af;
  -webkit-box-shadow: 0 0 6rem 6rem #1553af;
  -ms-box-shadow: 0 0 6rem 6rem #1553af;
}

.secondaryOverlay {
  composes: overlayCircle;
  z-index: 1;
  top: 3rem;
  right: 0;
  height: 12rem;
  width: 12rem;
  background: radial-gradient(#f9efae, #f79800);
  box-shadow: 0 0 4rem 4rem #f79800;
  -webkit-box-shadow: 0 0 4rem 4rem #f79800;
  -ms-box-shadow: 0 0 4rem 4rem #f79800;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .primaryOverlay {
    animation: 16s orbitAnimation linear infinite;
  }

  .secondaryOverlay {
    animation: 12s orbitAnimation linear infinite;
  }
}

@media screen and (min-width: 768px) {
  .primaryOverlay {
    bottom: -5rem;
    left: -5vw;
    height: 30rem;
    width: 30rem;
    box-shadow: 0 0 15rem 15rem #1553af;
    -webkit-box-shadow: 0 0 15rem 15rem #1553af;
    -ms-box-shadow: 0 0 15rem 15rem #1553af;
  }

  .secondaryOverlay {
    top: -5rem;
    right: -15vw;
    height: 25rem;
    width: 25rem;
    box-shadow: 0 0 12rem 12rem #f79800;
    -webkit-box-shadow: 0 0 12rem 12rem #f79800;
    -ms-box-shadow: 0 0 12rem 12rem #f79800;
  }
}

@keyframes yAxis {
  to {
    transform: translateY(20vh);
  }
}

@keyframes orbitAnimation {
  from {
    transform: rotate(0deg) translateX(14vw) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(14vw) rotate(-360deg);
  }
}
